.ant-pagination,
.ant-pagination ol,
.ant-pagination ul {
  // padding: 5px;
  list-style: none;
  letter-spacing: 1.2px;
  // padding-bottom: 7px;
  // padding-left: 10px;
  // border-bottom: 1px solid rgba(204, 204, 204, 0.65);
}

.thirdTabDiv {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(204, 204, 204, 0.65);

  .ant-pagination,
  .ant-pagination ol,
  .ant-pagination ul {
    list-style: none;
    margin-bottom: 11px;
  }
}

// .ricevuteInviateDiv {
//   width: 30%;
// }
// .paginationDiv {
//   width: 70%;
// }

.thirdTabDiv .ant-tabs {
  margin-bottom: -16px;
}

.scaricateAllInFattureBtn {
  border: none;
  border-radius: 22px;
  background-color: #d9faa5;
  // margin-right: 20px;
  // margin-top: 6px !important;
  margin-bottom: 11px;
  transition: 0s;
  span {
    font-weight: normal;
    color: #365702;
  }
  i,
  .extraB {
    color: #365702;
  }
}
.scaricateAllInFattureBtn:hover {
  background-color: #62960f;
  // margin-right: 20px;
  // margin-top: 6px !important;
  transition: 0s;
  span,
  i,
  .extraB {
    color: white;
  }
}

.scaricateAllOutFattureBtn {
  border: none;
  border-radius: 22px;
  background-color: #fcdcf2;
  margin-bottom: 11px;
  transition: 0s;
  span {
    font-weight: normal;
    color: #7c0356;
  }
  i,
  .extraB {
    color: #7c0356;
  }
}
.scaricateAllOutFattureBtn:hover {
  background-color: #b62789;
  border-color: #b62789;
  // margin-right: 20px;
  // margin-top: 6px !important;
  transition: 0s;
  span,
  i,
  .extraB {
    color: white;
  }
}

.fa-arrow-down,
.fa-arrow-up {
  padding-left: 6px;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  letter-spacing: 1.2px;
}

.filtraPerDiv {
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 1.2px;

  .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    background-color: #d1f6fc;
    color: #005b69;
    border-color: #d1f6fc;
    border-radius: 22px;

    .ant-select-arrow .ant-select-arrow-icon svg {
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      color: #005b69;
    }
  }
}


.masterP {
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 1.2px;

  .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    background-color: #ffefef !important;
    color: #005b69;
    border-color: #d1f6fc;
    border-radius: 22px;

    .ant-select-arrow .ant-select-arrow-icon svg {
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      color: #005b69;
    }
  }
}

.filtraPerP {
  padding-right: 10px;
}

.filtraPerSelect {
  width: 200px;
  .ant-select-selection-selected-value {
    text-align: center;
    float: none;
  }
}

.selectZipMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

// #fattura-container {
//   padding: 30px;
// }
