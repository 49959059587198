.dzu-inputLabel {
  color: black !important;
  background-color: white;
}

#drawerInviaFattura
  > div.ant-drawer-content-wrapper
  > div
  > div
  > div.ant-drawer-body
  > div.dzu-dropzone {
  background-color: white !important;
}

.dzu-dropzone {
  display: flex !important;
  flex-direction: column !important;
}

.custom-file-upload {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background: #efefef;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}


.allegati-upload-box {
  background-color: #ffffff;
  color: #02328a;
  border: 2px solid #6771b3;
  padding: 10px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.allegati-upload {
  display: flex;
  justify-content: center;
}


.rimuovi-allegati {
  margin-left: 50px;
  height: 66px !important;
  color: #fffbfc !important;
  background-color: #e84f4f !important;
}

#invioFatturaMessage {
}

#deleteFatturaBtn {
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 22px;
  background-color: #ffdbdb;
  border: none;
  color: #5a0000;
}

#deleteFatturaBtn:focus {
  outline: none;
}

#deleteFatturaBtn:hover {
  background-color: #b11616 !important;
  border: none;
  color: #fff !important;
}

#avantiBtnInvio {
  border-radius: 22px;
  background-color: #cbe4ff;
  color: #002e5f;
  transition: 0s;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  i {
    font-size: 16px !important;
    padding: 0px;
    padding-left: 6px;
  }
}

#avantiBtnInvio:focus {
  outline: none;
}

#avantiBtnInvio:hover {
  border-radius: 22px;
  background-color: #007bff;
  color: #fff;
  transition: 0s;
}

.steps-action {
  padding-top: 20px;
}

#indietroBtnInvio {
  border-radius: 22px;
  background-color: #ffedcb;
  color: #c58d24;
  transition: 0s;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  i {
    font-size: 16px !important;
    padding: 0px;
    padding-right: 6px;
  }
}

#indietroBtnInvio:focus {
  outline: none;
}

#indietroBtnInvio:hover {
  border-radius: 22px;
  background-color: #e69f1d;
  color: #fff;
  transition: 0s;
}

#inviaFatturBtnInvio {
  border-radius: 22px;
  background-color: #d6fddf;
  color: #1a8633;
  transition: 0s;
  border: none !important;
  i {
    font-size: 16px !important;
    padding: 0px;
    padding-left: 6px;
  }
}

#inviaFatturBtnInvio:focus {
  outline: none;
}

#inviaFatturBtnInvio:hover {
  border-radius: 22px;
  background-color: #2bbd32;
  color: #fff;
  transition: 0s;
}

#loadingFatture_rosso > div > span {
  color: #dd2424;
}

#loadingFatture_verde > div > span {
  color: #1bb11b;
}

#uploadedAndReadyContainer {
  height: calc(100vh - 330px);
  overflow-y: scroll !important;
  border: 2px solid #259296;
  padding: 10px;
  border-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 14px;

  div > div > label {
    font-size: 16px;
    padding-bottom: 10px;
    color: #075457;
  }
}

#percentageDivContainer {
  display: inline-grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-area: "checkBtn progressDiv inviaFinalContainer";
  padding-top: 20px;
  padding-bottom: 10px;
}

#checkAllBtnContainer {
  grid-area: "checkBtn";
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  button {
    width: 170px;
    border-radius: 22px;
  }

  #selezionaAllBtn {
    border-radius: 22px;
    background-color: #cbe4ff;
    color: #002e5f;
    transition: 0s;
    border: none !important;
    i {
      font-size: 14px !important;
      padding: 0px;
      padding-right: 6px;
    }
  }

  #selezionaAllBtn:hover {
    border-radius: 22px;
    background-color: #007bff;
    color: #fff;
  }

  #deselezionaAllBtn {
    background-color: #fceed4 !important;
    border: none;
    color: #91620c !important;
    border-radius: 22px;
    transition: 0s;
    i {
      font-size: 14px !important;
      padding: 0px;
      padding-right: 6px;
    }
  }
  #deselezionaAllBtn:hover {
    background-color: #e69f1d !important;
    border: none;
    color: #fff !important;
    transition: 0s;
  }
}

#percentageContainer {
  grid-area: "progressDiv";
  align-self: center;
  display: flex;
  flex-direction: row;
  align-self: baseline;
  justify-content: space-around;
}

#invioFinalBtnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

#loadingFatture_verde > div > svg > path.ant-progress-circle-trail {
  stroke: #e4ffe4 !important;
}

#loadingFatture_rosso > div > svg > path.ant-progress-circle-trail {
  stroke: #ffd8d8 !important;
}

.takeshi69 {
  //background: #002e5f !important;
}
