
@media only screen and (max-width: 1300px) {

  #mainNavBar > form > div > div > input {
    max-width: 55px; 
  }

  #mainNavBar > span {
    width: 150px;
    overflow: hidden;
  }


  #mainNavBar > form {
    display: none;
  }


}

  #mainNavBar > span {
    width: 200px;
    overflow: hidden;
  }



.afterBrandBtns {
  -ms-flex-direction: row;
  flex-direction: row;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
  z-index: 3;
}

.ant-tabs {
  margin-bottom: -5px;
}

#clientiDropBtn {
  margin-left: 8px;
  background-color: #e1fbff !important;
  border-color: #e1fbff !important ;
  color: #003b44 !important;
  transition: 0s;
  border-radius: 22px;

  .dropdown-toggle::after {
    border-top-color: #ee5858 !important;
    border-right-color: transparent;
    border-bottom-color: #ee5858 !important;
    border-left-color: transparent;
    margin-left: 4px;
  }
}

#clientiDropBtn:hover {
  margin-left: 8px;
  background-color: #0b8294 !important;
  border-color: #0b8294 !important ;
  color: #fff !important;
  transition: 0s;
  border-radius: 22px;
}

#mainNavBar > div.show.dropdown > div {
  height: calc(100vh - 57px);
}

#mainNavBar > div.show.dropdown {
  border: none;
}

#mainNavBar > div.show.dropdown > div {
  background-color: #e1fbff00;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  overflow: scroll;
  overflow-x: hidden;
  border: 2px solid rgba(255, 0, 0, 0);
  margin-top: 9px;
  padding-right: 10px !important;

  a {
    cursor: default;
  }

  button {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #e6aa32;
    color: #3a2a01;
    transition: 0s;
  }
  button:hover {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #e79417;
    color: #fff;
    transition: 0s;
  }

  .dropdown-item:hover {
    background-color: #e1fbff00;
  }

  .dropdown-item > button {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #ca62b2;
    color: #50023e;
    transition: 0s;
    letter-spacing: 1.2px;
  }
  .dropdown-item > button:hover {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #ad158c;
    color: #fff;
  }
}

#esciMainBtn {
  margin-left: 8px;
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important ;
  color: #4d4d4d !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px;
}
#esciMainBtn:hover {
  margin-left: 8px;
  background-color: #474747 !important;
  border-color: #474747 !important ;
  color: #fff !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px;
}

#impostazioniMainBtnX {
  margin-left: 8px;
  background-color: #ffe1bf !important;
  border-color: #ffe1bf !important ;
  color: #ac6514 !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px;
  height: 38px;
}
#impostazioniMainBtnX:hover {
  margin-left: 8px;
  background-color: #d88c35 !important;
  border-color: #d88c35 !important ;
  color: #fff !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 10px 5px;
  position: relative;
  min-width: 0;
  flex-direction: column;
  border: 1px solid black !important;
  border-radius: 5px !important;
  vertical-align: top;
}

#cercaField {
  padding: 10px 5px;
  color: #fff !important;
  border-bottom: none;
}

#root
  > div
  > div
  > div:nth-child(1)
  > nav
  > div.mr-auto.afterBrandBtns.navbar-nav
  > a.navBtn.btn.btn-outline-info {
  display: flex;
  flex-direction: row;

  .fas {
    padding-top: 3px;
  }
}

#mainNavBar {
  background: linear-gradient(
    90deg,
    rgba(37, 146, 150, 1) 0%,
    rgba(20, 205, 165, 1) 100%
  ) !important;
  // border-bottom: 1px solid #054749;
  height: 56px;
}

.brand {
  color: #fff !important;
}

#invioMainBtn {
  background-color: #d6fddf !important;
  border-color: #d6fddf !important;
  color: #1a8633 !important;
  height: 38px;
  border-radius: 22px;
  font-weight: normal !important;
  transition: 0s;
}

#invioMainBtn:hover {
  // background-color: #355fbb;
  // border-color: #355fbb;
  // color: #fff;
  background-color: #2bbd32 !important;
  border-color: #2bbd32 !important ;
  color: #fff !important;
  height: 38px;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s;
}

#esportaMainBtn {
  background-color: #d6fddf !important;
  border-color: #d6fddf !important;
  color: #28a745 !important;
  font-weight: normal !important;
  border-radius: 22px;
  transition: 0s;
}

#esportaMainBtn:hover {
  background-color: #22d64c !important;
  border-color: #22d64c !important ;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s;
}

#reportMainBtn {
  background-color: #fddadd !important;
  border-color: #fddadd !important;
  color: #dc3545 !important;
  font-weight: normal !important;
  border-radius: 22px;
  transition: 0s;
  height: 38px;
}

#reportMainBtn:hover {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important ;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s;
}

#stampaFattureBtn {
  background-color: #d2dcff !important;
  border-color: #d2dcff !important;
  color: #1360a0 !important;
  font-weight: normal !important;
  border-radius: 22px;
  padding: 6px 12px;
  transition: 0s;
  height: 38px;
}

#stampaFattureBtn:hover {
  background-color: #1360a0 !important;
  border-color: #1360a0 !important ;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s;
  height: 38px;
}

#impostazioniMainBtn {
  background-color: #fceed4 !important;
  border-color: #fceed4 !important;
  color: #e69f1d !important;
  font-weight: normal !important;
  border-radius: 22px;
  transition: 0s;
  margin-right: 0px !important;
}

#impostazioniMainBtn:hover {
  background-color: #e69f1d !important;
  border-color: #e69f1d !important ;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s;
}

.clientiBtn {
  border: none;
  border-radius: 22px;
  background-color: #fceed4;
  span,
  i {
    color: #e69f1d;
    background-color: #fceed4;
  }
  .dropdown-toggle::after {
    border-top-color: #e69f1d;
    border-right-color: transparent;
    border-bottom-color: #e69f1d;
    border-left-color: transparent;
    margin-left: 4px;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    transition: 0s;
    //padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #e69f1d;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: #e69f1d;
    border: 0;
  }
}

.clientiBtn:hover {
  span,
  i {
    color: #ffffff;
    background-color: #e69f1d;
  }
  background-color: #e69f1d;
  color: white;
  .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
  }
}

#mainNavBar > form > div {
  border-radius: 22px !important;
  border-color: #effdff !important;
  background-color: #effdff !important;
  text-align: center;
  height: 33px;
  letter-spacing: 1.2px;
}

#mainNavBar > form > div > div > input {
  border: none !important;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 1.2px;
  color: #086a77;
  caret-color: #309dac !important;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: none;
  pointer-events: none;
}
.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: none;
  pointer-events: none;
}
.MuiInput-underline.Mui-focused:after {
  border: none;
  transform: scaleX(0);
}

#mainNavBar > form > div > div > input {

 

  padding: 8px 30px;
}

#drawerInviaFattura
  > div.ant-drawer-content-wrapper
  > div
  > div
  > div.ant-drawer-header {
  background-color: #259296;
  border: 1.5px solid #259296;
  border-radius: 0px;
  text-align: center !important;

  .ant-drawer-title {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    text-align: center;
  }
}

// #drawerInviaFattura
//   > div.ant-drawer-content-wrapper
//   > div
//   > div
//   > div.ant-drawer-body
//   > div {
//   border: none;
//   background-color: #259296 !important;
//   display: flex;
//   flex-direction: row-reverse;

//   button {
//     background-color: #daffdc !important;
//     border-radius: 22px;
//     border-color: #daffdc;
//     color: #036808;
//     transition: 0s;
//     // margin-left: -20px;
//   }
//   button:hover {
//     background-color: #2bbd32 !important;
//     border-radius: 22px;
//     border-color: #2bbd32;
//     color: #ffffff;
//     transition: 0s;
//   }
//   button:active {
//     border-color: #2bbd32;
//   }
//   button:focus {
//     border-color: #2bbd32;
//   }
// }

#drawerInviaFattura
  > div
  > div.ant-drawer-content-wrapper
  > div
  > div
  > div.ant-drawer-header
  > button
  > i
  > svg
  > path {
  color: #fff;
}

#drawerInviaFattura
  > div.ant-drawer-content-wrapper
  > div
  > div
  > div.ant-drawer-body
  > input[type="file"] {
  width: 100%;
}

#drawerInviaFattura
  > div.ant-drawer-content-wrapper
  > div
  > div
  > div.ant-drawer-header
  > button {
  visibility: hidden;
}

#invioMessage {
  color: #fff;
  font-size: 20px;
  padding-right: 20px;
}

#pdfFattureModalDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;

  div {
    color: #000;

    span {
      color: #000;
    }
  }
}

#spanFiltraPDF {
  font-size: 16px;
  padding-top: 4px;
}


  
#mainNavBar > div.show.dropdown > div > div {
  background-color: white;
  padding: 34px;
  border-radius: 10px;

}
