.switch_settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.switch_settings h5 {
    margin-right: 35px;
}

.downloadSection {
    display: flex;
    justify-content: space-between;
    background-color: #13396a;
    justify-content: center;
    padding: 15px;
    align-items: center;
    flex-direction: row;
    color: white;
}


#scarica_text{
    font-weight: 600;
    font-size: large;
    margin-left: 10px;
    margin-right: 10px;
}
