.navToBtn {
  border: 1px solid rgb(80, 80, 80);
  border-radius: 4px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
}

.esportaButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #c9f8d4;
  p,
  i {
    color: #006919;
    background-color: #c9f8d4;
  }
}

.esportaButtonRightPane:hover {
  p,
  i {
    color: white;
    background-color: #279e43;
  }
  background-color: #279e43;
  color: white;
}

.stampaButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #c6dfff;
  p,
  i {
    color: #003881;
    background-color: #c6dfff;
  }
}

.stampaButtonRightPane:hover {
  p,
  i {
    color: white;
    background-color: #1e6bcf;
  }
  background-color: #1e6bcf;
  color: white;
}

.scaricaZipButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #f7c0f4;
  p,
  i {
    color: #64015f;
    background-color: #f7c0f4;
  }
}

.scaricaZipButtonRightPane:hover {
  p,
  i {
    color: white;
    background-color: #b923b2;
  }
  background-color: #b923b2;
}

.previewButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #c8f2f8;
  span,
  i {
    color: #016777;
    background-color: #c8f2f8;
  }
  .dropdown-toggle::after {
    border-top-color: #016777;
    border-right-color: transparent;
    border-bottom-color: #016777;
    border-left-color: transparent;
    margin-left: 4px;
  }
}

.previewButtonRightPane:hover {
  span,
  i {
    color: white;
    background-color: #199aad;
  }
  background-color: #199aad;
  color: white;
  .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
  }
}

.notificheButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #fde0bf;
  span,
  i {
    color: #8b4c03;
    background-color: #fde0bf;
  }
  .dropdown-toggle::after {
    border-top-color: #8b4c03;
    border-right-color: transparent;
    border-bottom-color: #8b4c03;
    border-left-color: transparent;
    margin-left: 4px;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    transition: 0s;
    //padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #eb820b;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: #cf8127;
    border: 0;
  }
}

.notificheButtonRightPane:hover {
  span,
  i {
    color: #ffffff;
    background-color: #cf8127;
  }
  background-color: #cf8127;
  color: white;
  .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
  }
}

.allegatiButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #fad3d3;
  span,
  i {
    color: #7a0101;
    background-color: #fad3d3;
  }
  .dropdown-toggle::after {
    border-top-color: #7a0101;
    border-right-color: transparent;
    border-bottom-color: #7a0101;
    border-left-color: transparent;
    margin-left: 4px;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    //padding: 0.25rem 1.5rem;
    clear: both;
    transition: 0s;
    font-weight: 400;
    color: #7a0101;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    white-space: nowrap;
    background-color: #be3333;
    border: 0;
  }
}

.allegatiButtonRightPane:hover {
  span,
  i {
    color: white;
    background-color: #be3333;
  }
  background-color: #be3333;
  color: white;
  .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
  }
}

div.ant-modal {
  width: 1000px !important;
}

.btnIconsRP {
  height: 20px;
  padding-right: 6px;
  margin-bottom: 4px;
}

.msgAllegati {
  padding: 10px;
  color: #d43939;
}

.nav-link {
  padding: 6px 14px !important;
}

.navRightPane {
  display: grid;
  justify-content: space-around;
  padding-top: 20px;
  letter-spacing: 1.2px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border-radius: 22px;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.listFattureCenter
  > div
  > div
  > div.SplitPane.vertical
  > div.Pane.vertical.Pane2
  > div
  > div
  > div.rightPane
  > nav
  > div
  > div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
  > div
  > div {
  background-color: rgba(255, 0, 0, 0);
  border-color: rgba(255, 0, 0, 0) !important;
}

.ant-modal-close {
  visibility: hidden;
  border: none !important;
}
.ant-modal-close {
  visibility: hidden;
  border: none !important;
}

.ant-modal-header {
  background-color: #259296;
  border-bottom-color: #259296;
  text-align: center;

  .anticon svg {
    display: inline-block;
    color: #fff !important;
  }

  .ant-modal-title {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1.2px;
  }
}

// .anticon svg {
//   display: inline-block;
//   color: #fff !important;
// }
/*
.ant-modal-body {
  color: #000;
  letter-spacing: 1.2px;
  #filtroP {
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    letter-spacing: 1.2px;
  }

  div {
    border-radius: 22px;
  }
  div:focus {
  }

  .selectZipMenu {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #000;

    #selectZipByInclude {
      border-radius: 22px;
      width: 50%;
      padding-right: 10px;
      color: #000;
      .anticon svg {
        display: inline-block;
        color: #000 !important;
      }
    }

    #selectZipByDate {
      border-radius: 22px;
      padding-left: 10px;
      width: 50%;
      color: #000;

      .anticon svg {
        display: inline-block;
        color: #000 !important;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    letter-spacing: 1.2px;

    button {
      margin-top: 30px !important;
      letter-spacing: 1.2px;
      border-radius: 22px;
    }
  }
}
*/


.ant-modal-footer {
  background-color: #259296;
  border-bottom-color: #259296;

  button {
    border-radius: 22px;
  }

  #popUpCancelBtn {
    color: #744705;
    background-color: #ffeaca;
    border: none;
    transition: 0s;
  }
  #popUpCancelBtn:hover {
    color: #fff;
    background-color: #ec981a;
    transition: 0s;
  }

  #popUpScaricaBtn {
    color: #036808;
    background-color: #daffdc;
    border: none;
    transition: 0s;
  }
  #popUpScaricaBtn:hover {
    color: #fff;
    background-color: #2bbd32;
    transition: 0s;
  }
}

.ant-modal-content {
  border-radius: 22px;
}

div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(1) {
  color: #744705;
  background-color: #ffeaca;
  border: none;
  transition: 0s;
  visibility: hidden;
}
div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(1):hover {
  color: #fff;
  background-color: #ec981a;
  transition: 0s;
}

div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(2) {
  color: #036808;
  background-color: #daffdc;
  border: none;
  transition: 0s;
}
div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(2):hover {
  color: #fff;
  background-color: #2bbd32;
  transition: 0s;
}
