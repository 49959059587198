
@media only screen and (max-width: 1300px) {

  .navRightPane {
    letter-spacing: normal !important;
  }
}


p,
.lableInfo {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0px !important;
}

.Pane.vertical.Pane1 {
  overflow: scroll;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  width: 12px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #5998bd;
  border-radius: 8px;
}

.info {
  padding: 0 20px;
}

span.navbar-text {
  padding: 0px;
}

form {
  margin: 0px 0px !important;
}

.checkBranStatus {
  color: green;
}

.fas {
  padding-right: 6px;
}

.searchIcon {
  padding-right: 0px;
}

.brand {
  cursor: pointer;
}

.shadow {
  z-index: 10;
}

.infoBrand {
  background-color: aqua;
  color: azure;
}

#tooltip-top > .tooltip-inner {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #353535;
}

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #353535;
}

// #inviaIcon {
//   color: #59a1db;
//   font-size: 20px;
// }

#opzioniIcon {
  color: #17a2b8;
  font-size: 20px;
}

.opzioniDrop {
  margin-left: 16px;
}

.navBtn {
  margin-right: 15px;
}

button {
  width: auto;
  text-align: center;
  font-size: 14px !important;
  margin-top: 0px !important;
}

.dropdown-toggle::after {
  border-top-color: #17a2b8;
  border-right-color: transparent;
  border-bottom-color: #17a2b8;
  border-left-color: transparent;
}

.dropdown-toggle::after {
  margin-left: 0;
}

.Resizer {
  background: rgb(141, 140, 140);
  // opacity: 0.5;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.Resizer.horizontal {
  height: 5px;
  margin: 0px 0;
  border-top: 2px solid #bdbdbd;
  border-bottom: 2px solid #bdbdbd;
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  background: #6d9cc9;
  border-top: 2px solid #6d9cc9;
  border-bottom: 2px solid #6d9cc9;
}

.Resizer.vertical {
  width: 5px;
  margin: 0 0px;
  border-left: 2px solid #bdbdbd;
  border-right: 2px solid #bdbdbd;
  cursor: col-resize;
}

.Resizer.vertical:hover {
  background: #6d9cc9;
  border-left: 2px solid #6d9cc9;
  border-right: 2px solid #6d9cc9;
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.leftPane {
  background-color: #ffffff;
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-columns: auto;
  grid-template-rows: 48% auto;
  grid-template-areas:
    "upperLeftPane"
    "lowerLeftPane";
}

.upperLeftPane {
  background-color: #ffffff;
  grid-area: upperLeftPane;
  overflow-y: scroll;
  border-bottom: 3px solid #bdbdbd;
}

.lowerLeftPane {
  background-color: #ffffff;
  grid-area: lowerLeftPane;
  overflow-y: scroll;
}
.lowerLeftPane::-webkit-scrollbar {
  display: none;
}

.listaFornitori {
  overflow-y: scroll;
  height: 82%;
  margin: 0 9px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}
.listFornitoriLeftPanelListGroup {
  // overflow-y: scroll;
}
.listFornitoriLeftPanelListGroup::-webkit-scrollbar {
  display: none;
}

.centerPane {
  background-color: #ffffff;
  height: 100%;
  //overflow-y: scroll;
  padding-top: 5px;
}

.listFattureCenter {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 120px;
}

tbody {
  border: 2px solid #9e9e9e;
}

.nav-tabs .nav-link.active {
  color: #5c98d4;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-size: 14px;
}

.nav-tabs .nav-link {
  color: #000;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link {
  font-size: 14px;
}

.nav-link {
  padding: 10px 6px;
}

.accordion {
  padding: 9px;
}

.filtroClienti {
  padding: 9px;
  padding-bottom: 0px;
}

.accordionToggle > .fa-building {
  padding-left: 0px;
}
.accordionToggle {
  cursor: pointer;
}
// .card {
//   width: 100%;
// }

.buttonToolbar {
  width: 100%;
  overflow-x: scroll;
}
.buttonToolbar::-webkit-scrollbar {
  display: none;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0px;
}

.sendSentBtn {
  font-size: 13px !important;
  outline: 0;
  box-shadow: none !important;
}

.elonCapitalizeAll {
  text-transform: capitalize !important;
}

.centerPanelDateYearTab {
  padding: 0 9px;
  padding-top: 3px;
}
.centerPanelDateMonthTab {
  padding: 0 9px;
}

.nav-link.active {
  font-weight: bold;
  color: #608bb3;
  background-color: rgb(233, 224, 209);
  border-top-color: rgb(211, 202, 186);
  border-right-color: rgb(211, 202, 186);
  border-bottom-color: rgb(233, 224, 209);
  border-left-color: rgb(211, 202, 186);
}

.rightPaneContainer {
  align-content: center;
  padding: 20px;
  padding-top: 4px;
  overflow: scroll;
  height: 100%;
}

.rightPane {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}

tbody {
  font-size: 13px;
}

.centerText {
  text-align: center;
}

.fontButtons {
  width: 100%;
  padding: 5px;
}
.themeButtons {
  width: 100%;
  padding: 5px;
  margin-top: 0px;
  padding-top: 0px;
}

.navRightPane {
  padding: 8px;
}

.previewButtons {
  padding: 0px 8px;
}

.notificheButtons.dropdown-item.active,
.notificheButtons.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 0, 0);
}

.allegatoLink.dropdown-item.active,
.allegatoLink.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 0, 0);
}

.esportaButtonRightPane {
  color: #28a745;
}

#listaClientiX {
  text-align: center;
  div {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    button {
      margin: 20px;
      border-radius: 22px;
      background-color: #92dcdf;
      border-color: #92dcdf;
      color: #065b5e;
      transition: 0s;
    }
    button:hover {
      margin: 20px;
      border-radius: 22px;
      background-color: #259296;
      border-color: #259296;
      color: #ffffff;
      transition: 0s;
    }
    .activeBtnUtenteDetail {
      margin: 20px;
      border-radius: 22px;
      background-color: #259296;
      border-color: #259296;
      color: #ffffff;
      transition: 0s;
    }
    button:focus {
      box-shadow: none;
    }
  }
}

#listClientiTitle {
  text-align: center;
  font-size: 18px;
  padding-top: 10px;
}

#spinnerLoadingX {
  margin-top: 25px;
  margin-left: 10px;
}
