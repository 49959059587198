.containerLogin {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-image: url(/static/media/b7.4eb2cda9.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

#rightDivLogin {
  width: 60vw;
  height: 100vh; }

#leftDivLogin {
  width: 40vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center; }

#leftDivLoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }
  #leftDivLoginContainer form {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px; }
  #leftDivLoginContainer input {
    border-radius: 22px;
    height: 46px;
    font-size: 18px; }
  #leftDivLoginContainer button {
    border-radius: 22px;
    padding: 10px 30px;
    font-size: 18px !important; }
  #leftDivLoginContainer form {
    width: 400px;
    margin-top: 100px;
    margin: auto;
    text-align: left; }
  #leftDivLoginContainer button {
    margin-top: 20px;
    width: 100%;
    font-weight: bold !important;
    background-color: #c8f5cb !important;
    border-radius: 22px;
    border-color: #c8f5cb !important;
    color: #0d7012 !important;
    transition: 0s; }
  #leftDivLoginContainer button:hover {
    background-color: #2bbd32 !important;
    border-radius: 22px;
    border-color: #2bbd32 !important;
    color: #ffffff !important;
    transition: 0s; }
  #leftDivLoginContainer #errorMessageLogin {
    color: #ffffff;
    font-size: 20px;
    padding-bottom: 20px;
    text-align: center; }

#titleLogin {
  padding-bottom: 100px;
  font-family: "Mitr", sans-serif;
  font-weight: 700 !important;
  font-size: 70px;
  color: #ffffff; }

@media only screen and (max-width: 1300px) {
  .navRightPane {
    letter-spacing: normal !important; } }

p,
.lableInfo {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0px !important; }

.Pane.vertical.Pane1 {
  overflow: scroll; }

::-webkit-scrollbar-track {
  background-color: #f5f5f5; }

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  width: 12px !important; }

::-webkit-scrollbar-thumb {
  background-color: #5998bd;
  border-radius: 8px; }

.info {
  padding: 0 20px; }

span.navbar-text {
  padding: 0px; }

form {
  margin: 0px 0px !important; }

.checkBranStatus {
  color: green; }

.fas {
  padding-right: 6px; }

.searchIcon {
  padding-right: 0px; }

.brand {
  cursor: pointer; }

.shadow {
  z-index: 10; }

.infoBrand {
  background-color: aqua;
  color: azure; }

#tooltip-top > .tooltip-inner {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #353535; }

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #353535; }

#opzioniIcon {
  color: #17a2b8;
  font-size: 20px; }

.opzioniDrop {
  margin-left: 16px; }

.navBtn {
  margin-right: 15px; }

button {
  width: auto;
  text-align: center;
  font-size: 14px !important;
  margin-top: 0px !important; }

.dropdown-toggle::after {
  border-top-color: #17a2b8;
  border-right-color: transparent;
  border-bottom-color: #17a2b8;
  border-left-color: transparent; }

.dropdown-toggle::after {
  margin-left: 0; }

.Resizer {
  background: #8d8c8c;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box; }

.Resizer:hover {
  transition: all 0.2s ease; }

.Resizer.horizontal {
  height: 5px;
  margin: 0px 0;
  border-top: 2px solid #bdbdbd;
  border-bottom: 2px solid #bdbdbd;
  cursor: row-resize;
  width: 100%; }

.Resizer.horizontal:hover {
  background: #6d9cc9;
  border-top: 2px solid #6d9cc9;
  border-bottom: 2px solid #6d9cc9; }

.Resizer.vertical {
  width: 5px;
  margin: 0 0px;
  border-left: 2px solid #bdbdbd;
  border-right: 2px solid #bdbdbd;
  cursor: col-resize; }

.Resizer.vertical:hover {
  background: #6d9cc9;
  border-left: 2px solid #6d9cc9;
  border-right: 2px solid #6d9cc9; }

.Resizer.disabled {
  cursor: not-allowed; }

.Resizer.disabled:hover {
  border-color: transparent; }

.leftPane {
  background-color: #ffffff;
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-columns: auto;
  grid-template-rows: 48% auto;
  grid-template-areas: "upperLeftPane" "lowerLeftPane"; }

.upperLeftPane {
  background-color: #ffffff;
  grid-area: upperLeftPane;
  overflow-y: scroll;
  border-bottom: 3px solid #bdbdbd; }

.lowerLeftPane {
  background-color: #ffffff;
  grid-area: lowerLeftPane;
  overflow-y: scroll; }

.lowerLeftPane::-webkit-scrollbar {
  display: none; }

.listaFornitori {
  overflow-y: scroll;
  height: 82%;
  margin: 0 9px;
  border: 1px solid #bdbdbd;
  border-radius: 5px; }

.listFornitoriLeftPanelListGroup::-webkit-scrollbar {
  display: none; }

.centerPane {
  background-color: #ffffff;
  height: 100%;
  padding-top: 5px; }

.listFattureCenter {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 120px; }

tbody {
  border: 2px solid #9e9e9e; }

.nav-tabs .nav-link.active {
  color: #5c98d4;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-size: 14px; }

.nav-tabs .nav-link {
  color: #000;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.nav-tabs .nav-link {
  font-size: 14px; }

.nav-link {
  padding: 10px 6px; }

.accordion {
  padding: 9px; }

.filtroClienti {
  padding: 9px;
  padding-bottom: 0px; }

.accordionToggle > .fa-building {
  padding-left: 0px; }

.accordionToggle {
  cursor: pointer; }

.buttonToolbar {
  width: 100%;
  overflow-x: scroll; }

.buttonToolbar::-webkit-scrollbar {
  display: none; }

.card-body {
  flex: 1 1 auto;
  padding: 0px; }

.sendSentBtn {
  font-size: 13px !important;
  outline: 0;
  box-shadow: none !important; }

.elonCapitalizeAll {
  text-transform: capitalize !important; }

.centerPanelDateYearTab {
  padding: 0 9px;
  padding-top: 3px; }

.centerPanelDateMonthTab {
  padding: 0 9px; }

.nav-link.active {
  font-weight: bold;
  color: #608bb3;
  background-color: #e9e0d1;
  border-top-color: #d3caba;
  border-right-color: #d3caba;
  border-bottom-color: #e9e0d1;
  border-left-color: #d3caba; }

.rightPaneContainer {
  align-content: center;
  padding: 20px;
  padding-top: 4px;
  overflow: scroll;
  height: 100%; }

.rightPane {
  background-color: #ffffff;
  height: 100%;
  width: 100%; }

tbody {
  font-size: 13px; }

.centerText {
  text-align: center; }

.fontButtons {
  width: 100%;
  padding: 5px; }

.themeButtons {
  width: 100%;
  padding: 5px;
  margin-top: 0px;
  padding-top: 0px; }

.navRightPane {
  padding: 8px; }

.previewButtons {
  padding: 0px 8px; }

.notificheButtons.dropdown-item.active,
.notificheButtons.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 0, 0); }

.allegatoLink.dropdown-item.active,
.allegatoLink.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: rgba(255, 255, 0, 0); }

.esportaButtonRightPane {
  color: #28a745; }

#listaClientiX {
  text-align: center; }
  #listaClientiX div {
    display: flex;
    flex-direction: column;
    margin-top: 25px; }
    #listaClientiX div button {
      margin: 20px;
      border-radius: 22px;
      background-color: #92dcdf;
      border-color: #92dcdf;
      color: #065b5e;
      transition: 0s; }
    #listaClientiX div button:hover {
      margin: 20px;
      border-radius: 22px;
      background-color: #259296;
      border-color: #259296;
      color: #ffffff;
      transition: 0s; }
    #listaClientiX div .activeBtnUtenteDetail {
      margin: 20px;
      border-radius: 22px;
      background-color: #259296;
      border-color: #259296;
      color: #ffffff;
      transition: 0s; }
    #listaClientiX div button:focus {
      box-shadow: none; }

#listClientiTitle {
  text-align: center;
  font-size: 18px;
  padding-top: 10px; }

#spinnerLoadingX {
  margin-top: 25px;
  margin-left: 10px; }

@media only screen and (max-width: 1300px) {
  #mainNavBar > form > div > div > input {
    max-width: 55px; }
  #mainNavBar > span {
    width: 150px;
    overflow: hidden; }
  #mainNavBar > form {
    display: none; } }

#mainNavBar > span {
  width: 200px;
  overflow: hidden; }

.afterBrandBtns {
  flex-direction: row; }

.dropdown-menu-right {
  right: 0;
  left: auto;
  z-index: 3; }

.ant-tabs {
  margin-bottom: -5px; }

#clientiDropBtn {
  margin-left: 8px;
  background-color: #e1fbff !important;
  border-color: #e1fbff !important;
  color: #003b44 !important;
  transition: 0s;
  border-radius: 22px; }
  #clientiDropBtn .dropdown-toggle::after {
    border-top-color: #ee5858 !important;
    border-right-color: transparent;
    border-bottom-color: #ee5858 !important;
    border-left-color: transparent;
    margin-left: 4px; }

#clientiDropBtn:hover {
  margin-left: 8px;
  background-color: #0b8294 !important;
  border-color: #0b8294 !important;
  color: #fff !important;
  transition: 0s;
  border-radius: 22px; }

#mainNavBar > div.show.dropdown > div {
  height: calc(100vh - 57px); }

#mainNavBar > div.show.dropdown {
  border: none; }

#mainNavBar > div.show.dropdown > div {
  background-color: #e1fbff00;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  overflow: scroll;
  overflow-x: hidden;
  border: 2px solid rgba(255, 0, 0, 0);
  margin-top: 9px;
  padding-right: 10px !important; }
  #mainNavBar > div.show.dropdown > div a {
    cursor: default; }
  #mainNavBar > div.show.dropdown > div button {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #e6aa32;
    color: #3a2a01;
    transition: 0s; }
  #mainNavBar > div.show.dropdown > div button:hover {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #e79417;
    color: #fff;
    transition: 0s; }
  #mainNavBar > div.show.dropdown > div .dropdown-item:hover {
    background-color: #e1fbff00; }
  #mainNavBar > div.show.dropdown > div .dropdown-item > button {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #ca62b2;
    color: #50023e;
    transition: 0s;
    letter-spacing: 1.2px; }
  #mainNavBar > div.show.dropdown > div .dropdown-item > button:hover {
    justify-self: center;
    width: 100% !important;
    border-radius: 22px;
    border: none;
    margin-bottom: 5px;
    background-color: #ad158c;
    color: #fff; }

#esciMainBtn {
  margin-left: 8px;
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  color: #4d4d4d !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px; }

#esciMainBtn:hover {
  margin-left: 8px;
  background-color: #474747 !important;
  border-color: #474747 !important;
  color: #fff !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px; }

#impostazioniMainBtnX {
  margin-left: 8px;
  background-color: #ffe1bf !important;
  border-color: #ffe1bf !important;
  color: #ac6514 !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px;
  height: 38px; }

#impostazioniMainBtnX:hover {
  margin-left: 8px;
  background-color: #d88c35 !important;
  border-color: #d88c35 !important;
  color: #fff !important;
  transition: 0s;
  border-radius: 22px;
  margin-right: 0px; }

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 10px 5px;
  position: relative;
  min-width: 0;
  flex-direction: column;
  border: 1px solid black !important;
  border-radius: 5px !important;
  vertical-align: top; }

#cercaField {
  padding: 10px 5px;
  color: #fff !important;
  border-bottom: none; }

#root
> div
> div
> div:nth-child(1)
> nav
> div.mr-auto.afterBrandBtns.navbar-nav
> a.navBtn.btn.btn-outline-info {
  display: flex;
  flex-direction: row; }
  #root
> div
> div
> div:nth-child(1)
> nav
> div.mr-auto.afterBrandBtns.navbar-nav
> a.navBtn.btn.btn-outline-info .fas {
    padding-top: 3px; }

#mainNavBar {
  background: linear-gradient(90deg, #259296 0%, #14cda5 100%) !important;
  height: 56px; }

.brand {
  color: #fff !important; }

#invioMainBtn {
  background-color: #d6fddf !important;
  border-color: #d6fddf !important;
  color: #1a8633 !important;
  height: 38px;
  border-radius: 22px;
  font-weight: normal !important;
  transition: 0s; }

#invioMainBtn:hover {
  background-color: #2bbd32 !important;
  border-color: #2bbd32 !important;
  color: #fff !important;
  height: 38px;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s; }

#esportaMainBtn {
  background-color: #d6fddf !important;
  border-color: #d6fddf !important;
  color: #28a745 !important;
  font-weight: normal !important;
  border-radius: 22px;
  transition: 0s; }

#esportaMainBtn:hover {
  background-color: #22d64c !important;
  border-color: #22d64c !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s; }

#reportMainBtn {
  background-color: #fddadd !important;
  border-color: #fddadd !important;
  color: #dc3545 !important;
  font-weight: normal !important;
  border-radius: 22px;
  transition: 0s;
  height: 38px; }

#reportMainBtn:hover {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s; }

#stampaFattureBtn {
  background-color: #d2dcff !important;
  border-color: #d2dcff !important;
  color: #1360a0 !important;
  font-weight: normal !important;
  border-radius: 22px;
  padding: 6px 12px;
  transition: 0s;
  height: 38px; }

#stampaFattureBtn:hover {
  background-color: #1360a0 !important;
  border-color: #1360a0 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s;
  height: 38px; }

#impostazioniMainBtn {
  background-color: #fceed4 !important;
  border-color: #fceed4 !important;
  color: #e69f1d !important;
  font-weight: normal !important;
  border-radius: 22px;
  transition: 0s;
  margin-right: 0px !important; }

#impostazioniMainBtn:hover {
  background-color: #e69f1d !important;
  border-color: #e69f1d !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 22px;
  transition: 0s; }

.clientiBtn {
  border: none;
  border-radius: 22px;
  background-color: #fceed4; }
  .clientiBtn span,
  .clientiBtn i {
    color: #e69f1d;
    background-color: #fceed4; }
  .clientiBtn .dropdown-toggle::after {
    border-top-color: #e69f1d;
    border-right-color: transparent;
    border-bottom-color: #e69f1d;
    border-left-color: transparent;
    margin-left: 4px; }
  .clientiBtn .dropdown-item {
    display: block;
    width: 100%;
    transition: 0s;
    clear: both;
    font-weight: 400;
    color: #e69f1d;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .clientiBtn .dropdown-item:hover {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: #e69f1d;
    border: 0; }

.clientiBtn:hover {
  background-color: #e69f1d;
  color: white; }
  .clientiBtn:hover span,
  .clientiBtn:hover i {
    color: #ffffff;
    background-color: #e69f1d; }
  .clientiBtn:hover .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent; }

#mainNavBar > form > div {
  border-radius: 22px !important;
  border-color: #effdff !important;
  background-color: #effdff !important;
  text-align: center;
  height: 33px;
  letter-spacing: 1.2px; }

#mainNavBar > form > div > div > input {
  border: none !important;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 1.2px;
  color: #086a77;
  caret-color: #309dac !important; }

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: none;
  pointer-events: none; }

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: none;
  pointer-events: none; }

.MuiInput-underline.Mui-focused:after {
  border: none;
  -webkit-transform: scaleX(0);
          transform: scaleX(0); }

#mainNavBar > form > div > div > input {
  padding: 8px 30px; }

#drawerInviaFattura
> div.ant-drawer-content-wrapper
> div
> div
> div.ant-drawer-header {
  background-color: #259296;
  border: 1.5px solid #259296;
  border-radius: 0px;
  text-align: center !important; }
  #drawerInviaFattura
> div.ant-drawer-content-wrapper
> div
> div
> div.ant-drawer-header .ant-drawer-title {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    text-align: center; }

#drawerInviaFattura
> div
> div.ant-drawer-content-wrapper
> div
> div
> div.ant-drawer-header
> button
> i
> svg
> path {
  color: #fff; }

#drawerInviaFattura
> div.ant-drawer-content-wrapper
> div
> div
> div.ant-drawer-body
> input[type="file"] {
  width: 100%; }

#drawerInviaFattura
> div.ant-drawer-content-wrapper
> div
> div
> div.ant-drawer-header
> button {
  visibility: hidden; }

#invioMessage {
  color: #fff;
  font-size: 20px;
  padding-right: 20px; }

#pdfFattureModalDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around; }
  #pdfFattureModalDiv div {
    color: #000; }
    #pdfFattureModalDiv div span {
      color: #000; }

#spanFiltraPDF {
  font-size: 16px;
  padding-top: 4px; }

#mainNavBar > div.show.dropdown > div > div {
  background-color: white;
  padding: 34px;
  border-radius: 10px; }

.dzu-inputLabel {
  color: black !important;
  background-color: white; }

#drawerInviaFattura
> div.ant-drawer-content-wrapper
> div
> div
> div.ant-drawer-body
> div.dzu-dropzone {
  background-color: white !important; }

.dzu-dropzone {
  display: flex !important;
  flex-direction: column !important; }

.custom-file-upload {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background: #efefef;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px; }

.allegati-upload-box {
  background-color: #ffffff;
  color: #02328a;
  border: 2px solid #6771b3;
  padding: 10px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  text-align: center; }

.allegati-upload {
  display: flex;
  justify-content: center; }

.rimuovi-allegati {
  margin-left: 50px;
  height: 66px !important;
  color: #fffbfc !important;
  background-color: #e84f4f !important; }

#deleteFatturaBtn {
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 22px;
  background-color: #ffdbdb;
  border: none;
  color: #5a0000; }

#deleteFatturaBtn:focus {
  outline: none; }

#deleteFatturaBtn:hover {
  background-color: #b11616 !important;
  border: none;
  color: #fff !important; }

#avantiBtnInvio {
  border-radius: 22px;
  background-color: #cbe4ff;
  color: #002e5f;
  transition: 0s;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px; }
  #avantiBtnInvio i {
    font-size: 16px !important;
    padding: 0px;
    padding-left: 6px; }

#avantiBtnInvio:focus {
  outline: none; }

#avantiBtnInvio:hover {
  border-radius: 22px;
  background-color: #007bff;
  color: #fff;
  transition: 0s; }

.steps-action {
  padding-top: 20px; }

#indietroBtnInvio {
  border-radius: 22px;
  background-color: #ffedcb;
  color: #c58d24;
  transition: 0s;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px; }
  #indietroBtnInvio i {
    font-size: 16px !important;
    padding: 0px;
    padding-right: 6px; }

#indietroBtnInvio:focus {
  outline: none; }

#indietroBtnInvio:hover {
  border-radius: 22px;
  background-color: #e69f1d;
  color: #fff;
  transition: 0s; }

#inviaFatturBtnInvio {
  border-radius: 22px;
  background-color: #d6fddf;
  color: #1a8633;
  transition: 0s;
  border: none !important; }
  #inviaFatturBtnInvio i {
    font-size: 16px !important;
    padding: 0px;
    padding-left: 6px; }

#inviaFatturBtnInvio:focus {
  outline: none; }

#inviaFatturBtnInvio:hover {
  border-radius: 22px;
  background-color: #2bbd32;
  color: #fff;
  transition: 0s; }

#loadingFatture_rosso > div > span {
  color: #dd2424; }

#loadingFatture_verde > div > span {
  color: #1bb11b; }

#uploadedAndReadyContainer {
  height: calc(100vh - 330px);
  overflow-y: scroll !important;
  border: 2px solid #259296;
  padding: 10px;
  border-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 14px; }
  #uploadedAndReadyContainer div > div > label {
    font-size: 16px;
    padding-bottom: 10px;
    color: #075457; }

#percentageDivContainer {
  display: inline-grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-area: "checkBtn progressDiv inviaFinalContainer";
  padding-top: 20px;
  padding-bottom: 10px; }

#checkAllBtnContainer {
  grid-area: "checkBtn";
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%; }
  #checkAllBtnContainer button {
    width: 170px;
    border-radius: 22px; }
  #checkAllBtnContainer #selezionaAllBtn {
    border-radius: 22px;
    background-color: #cbe4ff;
    color: #002e5f;
    transition: 0s;
    border: none !important; }
    #checkAllBtnContainer #selezionaAllBtn i {
      font-size: 14px !important;
      padding: 0px;
      padding-right: 6px; }
  #checkAllBtnContainer #selezionaAllBtn:hover {
    border-radius: 22px;
    background-color: #007bff;
    color: #fff; }
  #checkAllBtnContainer #deselezionaAllBtn {
    background-color: #fceed4 !important;
    border: none;
    color: #91620c !important;
    border-radius: 22px;
    transition: 0s; }
    #checkAllBtnContainer #deselezionaAllBtn i {
      font-size: 14px !important;
      padding: 0px;
      padding-right: 6px; }
  #checkAllBtnContainer #deselezionaAllBtn:hover {
    background-color: #e69f1d !important;
    border: none;
    color: #fff !important;
    transition: 0s; }

#percentageContainer {
  grid-area: "progressDiv";
  align-self: center;
  display: flex;
  flex-direction: row;
  align-self: baseline;
  justify-content: space-around; }

#invioFinalBtnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center; }

#loadingFatture_verde > div > svg > path.ant-progress-circle-trail {
  stroke: #e4ffe4 !important; }

#loadingFatture_rosso > div > svg > path.ant-progress-circle-trail {
  stroke: #ffd8d8 !important; }

.switch_settings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; }

.switch_settings h5 {
  margin-right: 35px; }

.downloadSection {
  display: flex;
  justify-content: space-between;
  background-color: #13396a;
  justify-content: center;
  padding: 15px;
  align-items: center;
  flex-direction: row;
  color: white; }

#scarica_text {
  font-weight: 600;
  font-size: large;
  margin-left: 10px;
  margin-right: 10px; }

.ant-pagination,
.ant-pagination ol,
.ant-pagination ul {
  list-style: none;
  letter-spacing: 1.2px; }

.thirdTabDiv {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(204, 204, 204, 0.65); }
  .thirdTabDiv .ant-pagination,
  .thirdTabDiv .ant-pagination ol,
  .thirdTabDiv .ant-pagination ul {
    list-style: none;
    margin-bottom: 11px; }

.thirdTabDiv .ant-tabs {
  margin-bottom: -16px; }

.scaricateAllInFattureBtn {
  border: none;
  border-radius: 22px;
  background-color: #d9faa5;
  margin-bottom: 11px;
  transition: 0s; }
  .scaricateAllInFattureBtn span {
    font-weight: normal;
    color: #365702; }
  .scaricateAllInFattureBtn i,
  .scaricateAllInFattureBtn .extraB {
    color: #365702; }

.scaricateAllInFattureBtn:hover {
  background-color: #62960f;
  transition: 0s; }
  .scaricateAllInFattureBtn:hover span,
  .scaricateAllInFattureBtn:hover i,
  .scaricateAllInFattureBtn:hover .extraB {
    color: white; }

.scaricateAllOutFattureBtn {
  border: none;
  border-radius: 22px;
  background-color: #fcdcf2;
  margin-bottom: 11px;
  transition: 0s; }
  .scaricateAllOutFattureBtn span {
    font-weight: normal;
    color: #7c0356; }
  .scaricateAllOutFattureBtn i,
  .scaricateAllOutFattureBtn .extraB {
    color: #7c0356; }

.scaricateAllOutFattureBtn:hover {
  background-color: #b62789;
  border-color: #b62789;
  transition: 0s; }
  .scaricateAllOutFattureBtn:hover span,
  .scaricateAllOutFattureBtn:hover i,
  .scaricateAllOutFattureBtn:hover .extraB {
    color: white; }

.fa-arrow-down,
.fa-arrow-up {
  padding-left: 6px; }

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  letter-spacing: 1.2px; }

.filtraPerDiv {
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 1.2px; }
  .filtraPerDiv .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    background-color: #d1f6fc;
    color: #005b69;
    border-color: #d1f6fc;
    border-radius: 22px; }
    .filtraPerDiv .ant-select-selection--single .ant-select-arrow .ant-select-arrow-icon svg {
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      color: #005b69; }

.masterP {
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 1.2px; }
  .masterP .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    background-color: #ffefef !important;
    color: #005b69;
    border-color: #d1f6fc;
    border-radius: 22px; }
    .masterP .ant-select-selection--single .ant-select-arrow .ant-select-arrow-icon svg {
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      color: #005b69; }

.filtraPerP {
  padding-right: 10px; }

.filtraPerSelect {
  width: 200px; }
  .filtraPerSelect .ant-select-selection-selected-value {
    text-align: center;
    float: none; }

.selectZipMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.monthsTabAdmin {
  width: calc(100% - 300px); }

.monthsTabUser {
  width: 100%;
  padding-right: 12px; }

.ant-tabs-tab-next-icon,
.ant-tabs-tab-prev-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 700;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  -webkit-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  color: #1890ff;
  background-color: white;
  width: 44px;
  height: 100%; }

.ant-badge-count {
  z-index: 1;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #00529e;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #d5ebff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
  transition: none !important; }

.ant-tabs-nav .ant-tabs-tab {
  transition: none !important;
  letter-spacing: 1.3px; }

.ant-tabs-nav:hover .ant-tabs-tab:hover {
  transition: none !important;
  color: #259296;
  font-weight: 600; }

.ant-tabs-nav .ant-tabs-tab-active {
  color: #259296;
  font-weight: 600;
  letter-spacing: 1.8px;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased !important; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ant-pagination-item {
  border-radius: 22px;
  background-color: #dbeaf8 !important;
  border-color: #dbeaf8 !important; }
  .ant-pagination-item a {
    color: #004280 !important; }

.ant-pagination-item:hover {
  border-radius: 22px;
  background-color: #259296 !important;
  border-color: #259296 !important; }
  .ant-pagination-item:hover a {
    color: #fff !important; }

.ant-pagination-item-active {
  border-radius: 22px;
  background-color: #259296 !important;
  border-color: #259296 !important; }
  .ant-pagination-item-active a {
    color: #fff !important; }

.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
  height: 46px; }

@media only screen and (max-width: 1300px) {
  #leftPaneContainerCards {
    width: 40vw !important;
    overflow: scroll;
    height: calc(80vh - 140px) !important; }
  #rightPaneContainerPreview {
    width: 60vw !important;
    overflow: scroll;
    height: calc(93vh - 255px) !important; } }

#fattureCardPreviewContainer {
  display: flex;
  flex-direction: row; }

.tdHead {
  width: auto !important; }

#leftPaneContainerCards {
  width: 40vw !important;
  overflow: scroll;
  height: calc(96vh - 220px); }

#rightPaneContainerPreview {
  width: 60vw !important;
  overflow: scroll;
  height: calc(100vh - 255px); }

#numeroFattureCaricate {
  padding-bottom: 20px !important;
  font-size: 16px; }

#realNumeroFattureCaricate {
  font-size: 20px;
  color: #259296;
  font-weight: bold; }

#fattureCaricateContainer {
  height: calc(100vh - 380px);
  overflow-y: scroll !important;
  border: 2px solid #259296;
  padding: 10px;
  border-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

.custom-file-upload {
  background-color: #bae9eb;
  color: #075457;
  border: 2px solid #259296;
  padding: 10px;
  border-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }
  .custom-file-upload p {
    font-size: 16px; }

#nameFatturaLoading {
  color: #075457;
  font-size: 16px; }

.PDFView {
  margin: 10px;
  margin-left: 10px; }

.paginationDiv {
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.65); }

@charset "UTF-8";
@media only screen and (max-width: 1300px) {
  #senderFattura {
    width: 40%;
    overflow: hidden; }
  #receiverFattura {
    width: 40%;
    overflow: hidden; } }

status {
  margin-right: 6px; }

td {
  text-align: left;
  vertical-align: top !important; }

table {
  width: 100%; }

::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid red; }

.containerFatturaCard {
  margin: 11px 11px;
  border-radius: 5px 5px 5px 0px;
  box-shadow: -2px 2px 10px 0px #a8a7a7;
  letter-spacing: 1.2px; }
  .containerFatturaCard #senderFattura {
    color: #636363; }
  .containerFatturaCard #numeroFattura {
    font-size: 10x;
    color: black; }
  .containerFatturaCard #firstRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px; }
  .containerFatturaCard #secondRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px; }
  .containerFatturaCard #thirdRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px; }
  .containerFatturaCard #fourthRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-top: 1px solid #dbeaf8;
    background-color: #dbeaf8;
    color: #004280;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }

.containerFatturaCard:hover {
  -webkit-transform: translate(3px, -3px);
          transform: translate(3px, -3px);
  box-shadow: -2px 2px 10px 0px #616161;
  cursor: pointer; }
  .containerFatturaCard:hover #nomeFattura {
    color: #01686b; }
  .containerFatturaCard:hover #fourthRowNG {
    border-top: 1px solid #cbfdff;
    background-color: #cbfdff; }

.containerFatturaCardACTIVE {
  -webkit-transform: translate(3px, -3px);
          transform: translate(3px, -3px);
  box-shadow: -2px 2px 10px 0px #616161;
  cursor: pointer;
  background: linear-gradient(90deg, #259296 0%, #14cda5 100%) !important;
  color: white; }
  .containerFatturaCardACTIVE #nomeFattura {
    color: #01686b; }
  .containerFatturaCardACTIVE #numeroFattura {
    color: white; }
  .containerFatturaCardACTIVE #fourthRowNG {
    border-top: 1px solid #cbfdff;
    background-color: #cbfdff; }
  .containerFatturaCardACTIVE #statoFattura {
    color: #292953 !important; }
  .containerFatturaCardACTIVE #senderFattura,
  .containerFatturaCardACTIVE #receiverFattura,
  .containerFatturaCardACTIVE #statusFatturaCard {
    font-weight: 600;
    color: #ffffff; }

.cardIconsLeft {
  border: 1px solid #00000000;
  padding: 7px;
  border-radius: 22px;
  margin-left: 8px;
  width: 30px;
  padding-right: 6px; }

.cardIconsRight {
  border: 1px solid #00000000;
  padding: 7px;
  border-radius: 22px;
  margin-left: 8px;
  width: 30px;
  padding-right: 6px; }

.cardIconsRightX {
  border: 1px solid #00000000;
  padding: 7px;
  border-radius: 22px;
  margin-right: 8px;
  width: 30px;
  padding-right: 6px; }

.fa-hashtag {
  color: #01686b;
  background-color: #cbfdff; }

.fa-edit {
  color: #250374;
  background-color: #dacbff; }

.fa-building {
  color: #6b0606;
  background-color: #ffcdcd; }

.fa-file-import {
  color: #03286b;
  background-color: #ccdcfc; }

.fa-sync-alt {
  color: #00693d;
  background-color: #ceffeb; }

.fa-file-archive {
  color: #64015f;
  background-color: #f7c0f4; }

.fa-check {
  background-color: #2bbd32;
  color: #fff; }

#icon_inviata {
  background-color: #bfbfbf !important;
  color: #fff !important; }

.fa-exclamation {
  background-color: #ffc814;
  color: #fff; }

.fa-trash {
  background-color: #fd2d2d;
  color: #fff; }

.fa-thumbs-up {
  background-color: #2bbd32;
  color: #fff; }

.fa-thumbs-down {
  background-color: #fd2d2d;
  color: #fff; }

.fa-times {
  background-color: #fd2d2d;
  color: #fff; }

#senderFattura,
#receiverFattura {
  font-weight: 600;
  color: #525252; }

#statusFatturaCard {
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 15px;
  color: #272727; }

.sub_fattura {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px; }

.sub_fattura:hover {
  background-color: #dbdbdb;
  border-radius: 5px; }

.ant-collapse-borderless {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.ant-collapse-header {
  background-color: #ffdf00; }

.navToBtn {
  border: 1px solid #505050;
  border-radius: 4px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle; }

.esportaButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #c9f8d4; }
  .esportaButtonRightPane p,
  .esportaButtonRightPane i {
    color: #006919;
    background-color: #c9f8d4; }

.esportaButtonRightPane:hover {
  background-color: #279e43;
  color: white; }
  .esportaButtonRightPane:hover p,
  .esportaButtonRightPane:hover i {
    color: white;
    background-color: #279e43; }

.stampaButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #c6dfff; }
  .stampaButtonRightPane p,
  .stampaButtonRightPane i {
    color: #003881;
    background-color: #c6dfff; }

.stampaButtonRightPane:hover {
  background-color: #1e6bcf;
  color: white; }
  .stampaButtonRightPane:hover p,
  .stampaButtonRightPane:hover i {
    color: white;
    background-color: #1e6bcf; }

.scaricaZipButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #f7c0f4; }
  .scaricaZipButtonRightPane p,
  .scaricaZipButtonRightPane i {
    color: #64015f;
    background-color: #f7c0f4; }

.scaricaZipButtonRightPane:hover {
  background-color: #b923b2; }
  .scaricaZipButtonRightPane:hover p,
  .scaricaZipButtonRightPane:hover i {
    color: white;
    background-color: #b923b2; }

.previewButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #c8f2f8; }
  .previewButtonRightPane span,
  .previewButtonRightPane i {
    color: #016777;
    background-color: #c8f2f8; }
  .previewButtonRightPane .dropdown-toggle::after {
    border-top-color: #016777;
    border-right-color: transparent;
    border-bottom-color: #016777;
    border-left-color: transparent;
    margin-left: 4px; }

.previewButtonRightPane:hover {
  background-color: #199aad;
  color: white; }
  .previewButtonRightPane:hover span,
  .previewButtonRightPane:hover i {
    color: white;
    background-color: #199aad; }
  .previewButtonRightPane:hover .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent; }

.notificheButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #fde0bf; }
  .notificheButtonRightPane span,
  .notificheButtonRightPane i {
    color: #8b4c03;
    background-color: #fde0bf; }
  .notificheButtonRightPane .dropdown-toggle::after {
    border-top-color: #8b4c03;
    border-right-color: transparent;
    border-bottom-color: #8b4c03;
    border-left-color: transparent;
    margin-left: 4px; }
  .notificheButtonRightPane .dropdown-item {
    display: block;
    width: 100%;
    transition: 0s;
    clear: both;
    font-weight: 400;
    color: #eb820b;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .notificheButtonRightPane .dropdown-item:hover {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: #cf8127;
    border: 0; }

.notificheButtonRightPane:hover {
  background-color: #cf8127;
  color: white; }
  .notificheButtonRightPane:hover span,
  .notificheButtonRightPane:hover i {
    color: #ffffff;
    background-color: #cf8127; }
  .notificheButtonRightPane:hover .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent; }

.allegatiButtonRightPane {
  border: none;
  border-radius: 22px;
  background-color: #fad3d3; }
  .allegatiButtonRightPane span,
  .allegatiButtonRightPane i {
    color: #7a0101;
    background-color: #fad3d3; }
  .allegatiButtonRightPane .dropdown-toggle::after {
    border-top-color: #7a0101;
    border-right-color: transparent;
    border-bottom-color: #7a0101;
    border-left-color: transparent;
    margin-left: 4px; }
  .allegatiButtonRightPane .dropdown-item {
    display: block;
    width: 100%;
    clear: both;
    transition: 0s;
    font-weight: 400;
    color: #7a0101;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .allegatiButtonRightPane .dropdown-item:hover {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    white-space: nowrap;
    background-color: #be3333;
    border: 0; }

.allegatiButtonRightPane:hover {
  background-color: #be3333;
  color: white; }
  .allegatiButtonRightPane:hover span,
  .allegatiButtonRightPane:hover i {
    color: white;
    background-color: #be3333; }
  .allegatiButtonRightPane:hover .dropdown-toggle::after {
    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent; }

div.ant-modal {
  width: 1000px !important; }

.btnIconsRP {
  height: 20px;
  padding-right: 6px;
  margin-bottom: 4px; }

.msgAllegati {
  padding: 10px;
  color: #d43939; }

.nav-link {
  padding: 6px 14px !important; }

.navRightPane {
  display: grid;
  justify-content: space-around;
  padding-top: 20px;
  letter-spacing: 1.2px; }

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border-radius: 22px;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px; }

#root
> div
> div
> div:nth-child(2)
> div
> div.listFattureCenter
> div
> div
> div.SplitPane.vertical
> div.Pane.vertical.Pane2
> div
> div
> div.rightPane
> nav
> div
> div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
> div
> div {
  background-color: rgba(255, 0, 0, 0);
  border-color: rgba(255, 0, 0, 0) !important; }

.ant-modal-close {
  visibility: hidden;
  border: none !important; }

.ant-modal-close {
  visibility: hidden;
  border: none !important; }

.ant-modal-header {
  background-color: #259296;
  border-bottom-color: #259296;
  text-align: center; }
  .ant-modal-header .anticon svg {
    display: inline-block;
    color: #fff !important; }
  .ant-modal-header .ant-modal-title {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1.2px; }

/*
.ant-modal-body {
  color: #000;
  letter-spacing: 1.2px;
  #filtroP {
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    letter-spacing: 1.2px;
  }

  div {
    border-radius: 22px;
  }
  div:focus {
  }

  .selectZipMenu {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #000;

    #selectZipByInclude {
      border-radius: 22px;
      width: 50%;
      padding-right: 10px;
      color: #000;
      .anticon svg {
        display: inline-block;
        color: #000 !important;
      }
    }

    #selectZipByDate {
      border-radius: 22px;
      padding-left: 10px;
      width: 50%;
      color: #000;

      .anticon svg {
        display: inline-block;
        color: #000 !important;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    letter-spacing: 1.2px;

    button {
      margin-top: 30px !important;
      letter-spacing: 1.2px;
      border-radius: 22px;
    }
  }
}
*/
.ant-modal-footer {
  background-color: #259296;
  border-bottom-color: #259296; }
  .ant-modal-footer button {
    border-radius: 22px; }
  .ant-modal-footer #popUpCancelBtn {
    color: #744705;
    background-color: #ffeaca;
    border: none;
    transition: 0s; }
  .ant-modal-footer #popUpCancelBtn:hover {
    color: #fff;
    background-color: #ec981a;
    transition: 0s; }
  .ant-modal-footer #popUpScaricaBtn {
    color: #036808;
    background-color: #daffdc;
    border: none;
    transition: 0s; }
  .ant-modal-footer #popUpScaricaBtn:hover {
    color: #fff;
    background-color: #2bbd32;
    transition: 0s; }

.ant-modal-content {
  border-radius: 22px; }

div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(1) {
  color: #744705;
  background-color: #ffeaca;
  border: none;
  transition: 0s;
  visibility: hidden; }

div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(1):hover {
  color: #fff;
  background-color: #ec981a;
  transition: 0s; }

div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(2) {
  color: #036808;
  background-color: #daffdc;
  border: none;
  transition: 0s; }

div.ant-modal-content > div.ant-modal-footer > div > button:nth-child(2):hover {
  color: #fff;
  background-color: #2bbd32;
  transition: 0s; }

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: "SF-Font", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

@font-face {
  font-family: "SF-Font";
  src: url(/static/media/sf-pro.fcf85e10.woff2);
  /* IE9 Compat Modes */
  src: url(/static/media/sf-pro.fcf85e10.woff2) format("woff2");
  /* Super Modern Browsers */ }

#fattura-elettronica > h1 {
  display: none; }

#fattura-elettronica > div:nth-child(2) {
  margin-top: 0px !important; }

#root
> div
> div
> div:nth-child(2)
> div
> div.listFattureCenter
> div
> div
> div.SplitPane.vertical
> div.Pane.vertical.Pane2
> div
> div
> div.rightPane
> nav
> div
> div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
> div
> div {
  width: 191px; }

#root
> div
> div
> div:nth-child(2)
> div
> div.listFattureCenter
> div
> div
> div.SplitPane.vertical
> div.Pane.vertical.Pane2
> div
> div
> div.rightPane
> nav
> div
> div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
> div
> div
> label.sendSentBtn.focus.btn.active.btn-info {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -10px; }

#root
> div
> div
> div:nth-child(2)
> div
> div.listFattureCenter
> div
> div
> div.SplitPane.vertical
> div.Pane.vertical.Pane2
> div
> div
> div.rightPane
> nav
> div
> div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
> div
> div
> label:nth-child(1) {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -10px; }

#root
> div
> div
> div:nth-child(2)
> div
> div.listFattureCenter
> div
> div
> div.SplitPane.vertical
> div.Pane.vertical.Pane2
> div
> div
> div.rightPane
> nav
> div
> div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
> div
> div
> label:nth-child(2) {
  padding-right: 22px; }

#root
> div
> div
> div:nth-child(2)
> div
> div.listFattureCenter
> div
> div
> div.SplitPane.vertical
> div.Pane.vertical.Pane2
> div
> div
> div.rightPane
> nav
> div
> div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
> div
> div
> label.sendSentBtn.btn.active.btn-info {
  padding-right: 22px; }

.MuiLinearProgress-barColorPrimary {
  background-color: #259296 !important; }

.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 2px;
  background-color: #259296;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.ant-tabs-nav .ant-tabs-tab-active {
  color: #259296;
  font-weight: 600;
  letter-spacing: 1.8px;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased !important; }

.checkBranStatus {
  color: #c1ffae; }

button {
  box-shadow: none !important; }

button:focus {
  box-shadow: none !important; }

