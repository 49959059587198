.monthsTabAdmin {
  width: -webkit-calc(100% - 300px);
  width: -moz-calc(100% - 300px);
  width: calc(100% - 300px);
}

.monthsTabUser {
  width: 100%;
  padding-right: 12px;
}

.ant-tabs-tab-next-icon,
.ant-tabs-tab-prev-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  -webkit-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  color: #1890ff;
  // padding-bottom: 6px;
  background-color: white;
  width: 44px;
  height: 100%;
}

.ant-badge-count {
  z-index: 1;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #00529e;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #d5ebff;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
  transition: none !important;
}

.ant-tabs-nav .ant-tabs-tab {
  transition: none !important;
  letter-spacing: 1.3px;
}

.ant-tabs-nav:hover .ant-tabs-tab:hover {
  transition: none !important;
  color: #259296;
  font-weight: 600;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #259296;
  font-weight: 600;
  letter-spacing: 1.8px;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-pagination-item {
  a {
    color: #004280 !important;
  }
  border-radius: 22px;
  background-color: #dbeaf8 !important;
  border-color: #dbeaf8 !important;
}

.ant-pagination-item:hover {
  a {
    color: #fff !important;
  }
  border-radius: 22px;
  background-color: #259296 !important;
  border-color: #259296 !important;
}

.ant-pagination-item-active {
  a {
    color: #fff !important;
  }
  border-radius: 22px;
  background-color: #259296 !important;
  border-color: #259296 !important;
}

.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
  height: 46px;
}
