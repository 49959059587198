.sub_fattura {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;

    padding:10px;
}


.sub_fattura:hover {
    background-color: rgb(219, 219, 219);
    border-radius: 5px;
}

.ant-collapse-borderless {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

}


.ant-collapse-header {
    background-color: #ffdf00;
}