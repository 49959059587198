.containerLogin {
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-image: url("../assets/b7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#rightDivLogin {
  // background-color: #cdfdff;
  width: 60vw;
  height: 100vh;
}
#leftDivLogin {
  // background-color: #17a5aa;
  width: 40vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
}
#leftDivLoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  form {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
  }

  input {
    border-radius: 22px;
    height: 46px;
    font-size: 18px;
  }

  button {
    border-radius: 22px;
    // height: 40px;
    // width: 140px;
    padding: 10px 30px;
    font-size: 18px !important;
  }

  form {
    width: 400px;
    margin-top: 100px;
    margin: auto;
    text-align: left;
  }

  button {
    margin-top: 20px;
    width: 100%;
    font-weight: bold !important;
    background-color: #c8f5cb !important;
    border-radius: 22px;
    border-color: #c8f5cb !important;
    color: #0d7012 !important;
    transition: 0s;
  }
  button:hover {
    background-color: #2bbd32 !important;
    border-radius: 22px;
    border-color: #2bbd32 !important;
    color: #ffffff !important;
    transition: 0s;
  }

  #errorMessageLogin {
    color: #ffffff;
    font-size: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}

#titleLogin {
  padding-bottom: 100px;
  font-family: "Mitr", sans-serif;
  font-weight: 700 !important;
  font-size: 70px;
  color: #ffffff;
}
