@media only screen and (max-width: 1300px) {
  #leftPaneContainerCards {
    width: 40vw !important;
    overflow: scroll;
    height: calc(80vh - 140px) !important;
  }

  #rightPaneContainerPreview {
    width: 60vw !important;
    overflow: scroll;
    height: calc(93vh - 255px) !important;
  }
}

#fattureCardPreviewContainer {
  display: flex;
  flex-direction: row;
}

.tdHead {
  width: auto !important;
}

#leftPaneContainerCards {
  width: 40vw !important;
  overflow: scroll;
  height: calc(96vh - 220px);
}

#rightPaneContainerPreview {
  width: 60vw !important;
  overflow: scroll;
  height: calc(100vh - 255px);
}

#numeroFattureCaricate {
  padding-bottom: 20px !important;
  font-size: 16px;
}

#realNumeroFattureCaricate {
  font-size: 20px;
  color: #259296;
  font-weight: bold;
}

#fattureCaricateContainer {
  height: calc(100vh - 380px);
  overflow-y: scroll !important;
  border: 2px solid #259296;
  padding: 10px;
  border-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.custom-file-upload {
  background-color: #bae9eb;
  color: #075457;
  border: 2px solid #259296;
  padding: 10px;
  border-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  p {
    font-size: 16px;
  }
}

#nameFatturaLoading {
  color: #075457;
  font-size: 16px;
}

.PDFView {
  margin: 10px;
  margin-left: 10px;
}

.paginationDiv {
  display: flex;
  justify-content: center;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.65);
}
