
@media only screen and (max-width: 1300px) {

  #senderFattura  {
    width: 40%;
    overflow: hidden;
  }
  
  #receiverFattura  {
    width: 40%;
    overflow: hidden;
  }
  
}



status {
  margin-right: 6px;
}

td {
  text-align: left;
  vertical-align: top !important;
}

table {
  width: 100%;
}

::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid red;
}

.containerFatturaCard {
  margin: 11px 11px;
  // margin-bottom: 25px;
  // padding: 15px 10px;
  border-radius: 5px 5px 5px 0px;
  box-shadow: -2px 2px 10px 0px #a8a7a7;
  letter-spacing: 1.2px;

  #senderFattura {
    color: #636363;
  }

  #numeroFattura {
    font-size: 10x;
    color: black;
  }

  #firstRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
  }

  #secondRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
  }

  #thirdRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
  }

  #fourthRowNG {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-top: 1px solid #dbeaf8;
    background-color: #dbeaf8;
    color: #004280;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.containerFatturaCard:hover {
  transform: translate(3px, -3px);
  box-shadow: -2px 2px 10px 0px #616161;
  cursor: pointer;

  #nomeFattura {
    color: #01686b;
  }
  #fourthRowNG {
    border-top: 1px solid #cbfdff;
    background-color: #cbfdff;
  }
}

.containerFatturaCardACTIVE {
  transform: translate(3px, -3px);
  box-shadow: -2px 2px 10px 0px #616161;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(37, 146, 150, 1) 0%, rgba(20, 205, 165, 1) 100%) !important;
  color: white;
  #nomeFattura {
    color: #01686b;
  }

  #numeroFattura {
    color: white;
  }

  #fourthRowNG {
    border-top: 1px solid #cbfdff;
    background-color: #cbfdff;
  }
  
  #statoFattura {
    color: rgb(41, 41, 83) !important;
  }

  #senderFattura,
  #receiverFattura,
  #statusFatturaCard {
    font-weight: 600;
    color: #ffffff;
  }
}

.cardIconsLeft {
  border: 1px solid #00000000;
  padding: 7px;
  border-radius: 22px;
  margin-left: 8px;
  width: 30px;
  padding-right: 6px;
}

.cardIconsRight {
  border: 1px solid #00000000;
  padding: 7px;
  border-radius: 22px;
  margin-left: 8px;
  width: 30px;
  padding-right: 6px;
}


.cardIconsRightX {
  border: 1px solid #00000000;
  padding: 7px;
  border-radius: 22px;
  margin-right: 8px;
  width: 30px;
  padding-right: 6px;
}


.fa-hashtag {
  color: #01686b;
  background-color: #cbfdff;
}

.fa-edit {
  color: #250374;
  background-color: #dacbff;
}

.fa-building {
  color: #6b0606;
  background-color: #ffcdcd;
}

.fa-file-import {
  color: #03286b;
  background-color: #ccdcfc;
}

.fa-sync-alt {
  color: #00693d;
  background-color: #ceffeb;
}

.fa-file-archive {
  color: #64015f;
  background-color: #f7c0f4;
}

.fa-check {
  background-color: #2bbd32;
  color: #fff;
}

#icon_inviata {
  background-color: #bfbfbf !important;
  color: #fff !important;
}

.fa-exclamation {
  background-color: #ffc814;
  color: #fff;
}

.fa-trash {
  background-color: #fd2d2d;
  color: #fff;
}

.fa-thumbs-up {
  background-color: #2bbd32;
  color: #fff;
}

.fa-thumbs-down {
  background-color: #fd2d2d;
  color: #fff;
}

.fa-times {
  background-color: #fd2d2d;
  color: #fff;
}

#senderFattura,
#receiverFattura {
  font-weight: 600;
  color: #525252;
}

#statusFatturaCard {
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 15px;
  color: #272727;
}
