body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  // background: linear-gradient(90deg, #44c1dd 0%, #aee647 100%);
  // color: white;

  // font-family: "Montserrat", sans-serif;
  font-family: "SF-Font", sans-serif;
  // Make the etx pixel perfect
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: "SF-Font";
  src: url("./components/assets/sf-pro.woff2"); /* IE9 Compat Modes */
  src: url("./components/assets/sf-pro.woff2") format("woff2"); /* Super Modern Browsers */
}
#fattura-elettronica > h1 {
  display: none;
}

#fattura-elettronica > div:nth-child(2) {
  margin-top: 0px !important;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.listFattureCenter
  > div
  > div
  > div.SplitPane.vertical
  > div.Pane.vertical.Pane2
  > div
  > div
  > div.rightPane
  > nav
  > div
  > div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
  > div
  > div {
  width: 191px;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.listFattureCenter
  > div
  > div
  > div.SplitPane.vertical
  > div.Pane.vertical.Pane2
  > div
  > div
  > div.rightPane
  > nav
  > div
  > div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
  > div
  > div
  > label.sendSentBtn.focus.btn.active.btn-info {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -10px;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.listFattureCenter
  > div
  > div
  > div.SplitPane.vertical
  > div.Pane.vertical.Pane2
  > div
  > div
  > div.rightPane
  > nav
  > div
  > div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
  > div
  > div
  > label:nth-child(1) {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -10px;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.listFattureCenter
  > div
  > div
  > div.SplitPane.vertical
  > div.Pane.vertical.Pane2
  > div
  > div
  > div.rightPane
  > nav
  > div
  > div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
  > div
  > div
  > label:nth-child(2) {
  padding-right: 22px;
}

#root
  > div
  > div
  > div:nth-child(2)
  > div
  > div.listFattureCenter
  > div
  > div
  > div.SplitPane.vertical
  > div.Pane.vertical.Pane2
  > div
  > div
  > div.rightPane
  > nav
  > div
  > div.navToBtn.previewButtonRightPane.show.dropdown.nav-item
  > div
  > div
  > label.sendSentBtn.btn.active.btn-info {
  padding-right: 22px;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #259296 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 2px;
  background-color: #259296;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #259296;
  font-weight: 600;
  letter-spacing: 1.8px;
  font-size: 15px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased !important;
}

.checkBranStatus {
  color: #c1ffae;
}

button {
  box-shadow: none !important;
}
button:focus {
  box-shadow: none !important;
}
